import React from "react"
import { Img } from 'remotion'
import { BackdropFilter } from '../backdrop-filter'
import { useAimationEffect } from '../hooks/useAimationEffect'

interface IBackdropFilterImgProps {
    src: string,
    durationInFrames: number
    style?: React.CSSProperties,
    effect?: 'zoomIn' | 'slowUp' | 'slowDown',
}

export const BackdropFilterImg: React.FC<IBackdropFilterImgProps> = ({
    src,
    style,
    effect,
    durationInFrames
}) => {
    const { effectStyle } = useAimationEffect({ durationInFrames, effect, })
    return <BackdropFilter
        style={style}
        backgroundElement={<Img src={src} style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            ...effectStyle
        }}
        />}
        foregroundElement={
            <div style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
            }}>
                <Img
                    src={src}
                    style={{
                        objectFit: 'contain',
                        opacity: 0,
                        width: '100%',
                    }} />
                <Img src={src} style={{
                    height: '100%',
                    position: 'absolute',
                    objectFit: 'contain',
                    objectPosition: 'center',
                    // imageRendering: 'pixelated',
                    ...effectStyle
                }} />
            </div>
        }
    />
}

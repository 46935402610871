import { useMemo } from 'react';
import {
    AbsoluteFill,
    useVideoConfig,
    Audio,
} from 'remotion';
import { ISceneModelData } from './type';
import { linearTiming, TransitionSeries } from '@remotion/transitions';
import { useTransitions } from '../../components/hooks/useTransition';
import { Serializer } from './serializer';
import { SHScene } from '../../components/sh-scene';
import Watermark from '../../components/watermark';

const transitionTime = 5

export const SceneModel: React.FC<ISceneModelData> = (videoData) => {

    const { fps } = useVideoConfig();
    const { transitions } = useTransitions()

    const backgroundMusicVolume = Math.max(0, Math.min(1, videoData.backgroundMusic?.volume ?? 0))

    const scenes = useMemo(() => {
        const serializer = new Serializer({ videoData, fps, transitions })
        return serializer.data()
    }, [])


    const scenesComps = [...scenes].map((item, index) => {
        return {
            transitions: item.transitions,
            duration: item.avatar.durationInFrames,
            comp: <SHScene key={index} item={item} videoData={videoData} />
        }
    })

    const render = scenesComps.reverse().reduce((leftScene, rightScene) => {
        return {
            transitions: rightScene.transitions,
            duration: leftScene.duration + rightScene.duration - transitionTime,
            comp: <TransitionSeries >
                <TransitionSeries.Sequence durationInFrames={leftScene.duration}>
                    {leftScene.comp}
                </TransitionSeries.Sequence>
                <TransitionSeries.Transition
                    presentation={leftScene.transitions}
                    timing={linearTiming({
                        durationInFrames: transitionTime
                    })}
                />
                <TransitionSeries.Sequence durationInFrames={leftScene.duration + rightScene.duration}>
                    {rightScene.comp}
                </TransitionSeries.Sequence>
            </TransitionSeries>
        }
    }).comp

    return (
        <AbsoluteFill style={{ backgroundColor: videoData?.backgroundColor || '#625454' }}>
            {videoData.watermark && <Watermark content={videoData.watermark} />}
            {/* 基础场景组件 */}
            {render}
            {/* 背景音乐 */}
            {
                videoData?.backgroundMusic && videoData.backgroundMusic.url ?
                    <AbsoluteFill>
                        {/* eslint-disable-next-line @remotion/volume-callback */}
                        <Audio volume={backgroundMusicVolume} crossOrigin='anonymous' src={videoData.backgroundMusic.url} />
                    </AbsoluteFill> : null
            }

        </AbsoluteFill >
    );
};

import { z } from 'zod';
import { ScenesDataType } from '../../type';
import { BaseSerializer } from '../common/serializer';

export class Serializer extends BaseSerializer {
    static code = 'SceneModel';

    private static transitionTime = 5;

    private static DigitalManPlacementType = ['topRight', 'bottomLeft', 'topLeft', 'none', 'lowerLayerImage'] as const;


    public static get dataSchema() {
        return z.object({});
    }

    public static frameData = ({ props }) => {
        const fps = props.fps ?? 30;
        let totalFrame = 0
        const videoData = props as unknown as ScenesDataType
        videoData.scenes.forEach((scene) => {
            if (Math.floor(scene.avatar.audio.length / 1000 * fps) > this.transitionTime) totalFrame += Math.floor(scene.avatar.audio.length / 1000 * fps)
        })
        return {
            fps,
            transitionTime: Serializer.transitionTime,
            backgroundMusicVolume: props.backgroundMusic?.volume ?? 0.3,
            durationInFrames: totalFrame,
        };
    }

    data() {
        // 标记最终场景, 为最后一个动画补上过渡丢失的帧数
        const extraFrame = (Serializer.transitionTime * this.videoData.scenes.length)
        const lastScreen = this.videoData.scenes[this.videoData.scenes.length - 1]
        lastScreen.extraFrame = extraFrame


        const arr = [...this.videoData.scenes].map((screen, index, array) => {
            let avatarFrom = 0
            for (let i = 0; i < index + 1; i++) {
                if (i > 0) {
                    const lastScreen = array[i - 1]
                    avatarFrom += lastScreen.avatar.audio.length / 1000 * this.fps
                }
            }
            const avatarDurationInFrames = screen.avatar.audio.length / 1000 * this.fps
            // const DigitalManPlacementType = Serializer.DigitalManPlacementType.filter(item => {
            //     if (!this.videoData.needAvatar && ['none', 'lowerLayerImage'].includes(item)) {
            //         return false
            //     }
            //     return true
            // })
            const digitalManPlacementType = screen.avatar?.video?.placement ?? 'topRight';

            // 获取场景切换的动效果
            let currentTransition = 'none'
            const rule = `[${screen.id}]-[${[...this.videoData.scenes][index + 1]?.id}]`
            if (this.videoData.transitions?.[rule]) currentTransition = this.videoData.transitions[rule].type

            return {
                ...screen,
                scripts: screen.scripts.map((script) => script.text),
                transitions: this.transitions[currentTransition],
                assets: screen.assets.map((asset) => {
                    // assetsDurationCount += (asset.duration <= 0 ? screen.avatar.audio.length : asset.duration)

                    return {
                        ...asset,
                        animation: asset.animation ?? 'zoomIn',
                        from: asset.start || avatarFrom,
                        durationInFrames: asset.duration && asset.duration !== -1 ? asset.duration / 1000 * this.fps : avatarDurationInFrames
                    }
                }),
                avatar: {
                    ...screen.avatar,
                    from: avatarFrom,
                    durationInFrames: avatarDurationInFrames,
                    video: {
                        ...screen.avatar.video,
                        placement: digitalManPlacementType,
                        from: avatarFrom,
                        durationInFrames: avatarDurationInFrames,
                        width: digitalManPlacementType === 'lowerLayerImage' ? '100%' : screen.avatar.video?.width || '100%',
                        height: digitalManPlacementType === 'lowerLayerImage' ? '100%' : screen.avatar.video?.height || '100%',
                    },
                    audio: {
                        ...screen.avatar.audio,
                        src: screen.avatar.audio.url
                    },
                    words: screen.avatar.words.map((word, index, array) => {
                        const nextSubtitle = array[index + 1] ?? null;
                        const subtitleStartFrame = word.start / 1000;
                        let end = word.end ? word.end / 1000 : (nextSubtitle?.start ?? 0) / 1000
                        if (!nextSubtitle) {
                            end = (screen.avatar.audio.length ?? 0) / 1000
                        }
                        return {
                            ...word,
                            start: subtitleStartFrame,
                            end
                        }
                    })
                }

            }
        }).reverse()

        return arr
    }
}


import React, { useEffect, useRef } from 'react';

interface WatermarkProps {
    content: string;
    width?: number;
    height?: number;
    fontSize?: number;
    opacity?: number;
    rotate?: number;
}

const Watermark: React.FC<WatermarkProps> = ({
    content,
    width = 300,
    height = 200,
    fontSize = 18,
    opacity = 0.15,
    rotate = -30,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        canvas.width = width;
        canvas.height = height;

        ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
        ctx.font = `${fontSize}px Arial`;
        ctx.rotate((rotate * Math.PI) / 180);
        ctx.fillText(content, 0, height / 2);

        const base64Url = canvas.toDataURL();

        if (containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${base64Url})`;
        }
    }, [content, width, height, fontSize, opacity, rotate]);

    return (
        <div
            ref={containerRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                zIndex: 9999,
                backgroundRepeat: 'repeat',
            }}
        />
    );
};

export default Watermark;

import { SpliceModel } from './index';
import data from './type';
import { SHComposition } from '../../components/sh-composition';
import { Serializer } from './serializer';

export const SpliceModelRegister = () => {
    return (
        <SHComposition
            id={Serializer.code}
            component={SpliceModel}
            dataSchema={Serializer.dataSchema}
            frameData={Serializer.frameData}
            data={data}
        />
    );
};

import { ISubtitleJsonItem } from '../type'
import { useEffect, useState } from 'react';
import { TheBoldFont, loadFont } from '../../../aseets/load-font';

import { useCurrentFrame, useVideoConfig, interpolate } from 'remotion';
import { makeTransform, scale, translateY } from '@remotion/animation-utils';

interface ShadowLineProps {
  currentSubtitle: ISubtitleJsonItem
  font?: {
    size: number;
    weight: number;
    color: string;
    highlightColor: string;
    highlightShadow: string;
  };

}

loadFont()
const fontFamily = TheBoldFont;

const defaultFont = {
  size: 100,
  weight: 700,
  color: 'white',
  highlightColor: 'yellow',
  highlightShadow: 'rgb(0, 0, 0) 5px -4px 6px, rgb(0, 0, 0) -3px 3px 6px, rgb(0, 0, 0) 5px 2px 6px, rgb(0, 0, 0) -2px -4px 6px'

}

export const SingleWord = ({
  currentSubtitle,
  font = JSON.parse(JSON.stringify(defaultFont))
}: ShadowLineProps) => {
  const [enter, setEnter] = useState(1);

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  useEffect(() => {
    const currentTime = frame / fps; // Assuming 30fps
    const num = (currentTime - currentSubtitle.start) / (currentSubtitle.end - currentSubtitle.start);
    setEnter(num)
  }, [frame])

  const RenderText = ({ shadow = false }) => {
    return (
      <div
        style={{
          position: shadow ? undefined : 'absolute',
          zIndex: shadow ? undefined : 1,
          fontSize: font.size ?? defaultFont.size,
          color: font.color ?? defaultFont.color,
          WebkitTextStroke: shadow ? '20px black' : undefined,
          transform: makeTransform([
            scale(interpolate(enter, [0, 1], [0.8, 1])),
            translateY(interpolate(enter, [0, 1], [50, 0])),
          ]),
          fontFamily,
          textTransform: 'uppercase',
        }}
      >
        {currentSubtitle.word?.replaceAll(',', '').replaceAll('.', '')}
      </div>
    )

  }


  return (
    <>
      <RenderText shadow={false} />
      <RenderText shadow />
    </>

  )

}
import { ISubtitleJsonItem } from '../type'
interface ShadowLineProps {
  subtitleJson: ISubtitleJsonItem[];
  currentSubtitle: ISubtitleJsonItem
  font?: {
    size: number;
    weight: number;
    color: string;
    highlightColor: string;
    highlightShadow: string;
  };

}

const defaultFont = {
  size: 50,
  weight: 700,
  color: 'white',
  highlightColor: 'yellow',
  highlightShadow: 'rgb(0, 0, 0) 5px -4px 6px, rgb(0, 0, 0) -3px 3px 6px, rgb(0, 0, 0) 5px 2px 6px, rgb(0, 0, 0) -2px -4px 6px'

}

export const ShadowLine = ({
  subtitleJson,
  currentSubtitle,
  font = JSON.parse(JSON.stringify(defaultFont))
}: ShadowLineProps) => {


  return (
    <div style={{
      fontSize: font.size ?? defaultFont.size,
      fontWeight: font.weight ?? defaultFont.weight
    }}>
      {subtitleJson.filter((f) => f.paragraph === currentSubtitle?.paragraph).map((item) => (
        <>
          <div
            style={{
              color: item?.active ? font.highlightColor ?? defaultFont.highlightColor : font.color ?? defaultFont.color,
              textShadow: item?.active ? font.highlightShadow ?? defaultFont.highlightShadow : 'none',
            }}
          >{item.word?.replaceAll(',', '').replaceAll('.', '')}</div>
          {' '}
        </>

      ))}

    </div>
  )

}
import { Composition } from 'remotion';


interface SHCompositionProps {
    id: string;
    component;
    frameData: (props) => { fps: number; transitionTime: number; backgroundMusicVolume; durationInFrames: number; }
    data;
    dataSchema;
    width?: number;
    height?: number;


}

export const SHComposition: React.FC<SHCompositionProps> = (props) => {
    const { id, component, frameData, data, dataSchema, width = 720, height = 1280 } = props


    return <Composition
        id={id}
        component={component}
        calculateMetadata={frameData}
        schema={dataSchema}
        width={width}
        height={height}
        defaultProps={data}
    />

}

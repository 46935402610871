
export abstract class BaseSerializer {
    static code = 'BaseSerializer';
    videoData
    fps
    transitions


    constructor({ videoData = null, fps = 0, transitions = {} }) {
        this.videoData = videoData;
        this.fps = fps;
        this.transitions = transitions;
    }


    static dataSchema;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static frameData({ props }): { fps: number, transitionTime: number, backgroundMusicVolume: number, durationInFrames: number } {
        // Provide a default implementation or throw an error if not meant to be used directly
        throw new Error("Method not implemented.");
    }

    abstract data()
}


import { useVideoConfig } from 'remotion'
import { slide } from "@remotion/transitions/slide";
import { fade } from "@remotion/transitions/fade";
import { none } from "@remotion/transitions/none";
import { wipe } from "@remotion/transitions/wipe";
import { clockWipe } from "@remotion/transitions/clock-wipe";
import { flip } from "@remotion/transitions/flip";
// import { cube } from "@remotion-dev/cube-presentation";
function shuffle(array: any[]) {
    // 数组乱序
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // 当数组中仍然存在元素时
    while (currentIndex !== 0) {

        // 选择剩余元素中的一个
        // eslint-disable-next-linew @remotion/deterministic-randomness
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // 交换它和当前元素
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const useTransitions = () => {
    const { width, height } = useVideoConfig();
    const getALlTransitions = () => {
        const TransitionAnimation = {
            slideLeft: slide({
                direction: 'from-right',
            }),
            slideRight: slide({ direction: 'from-left' }),
            slideDown: slide({ direction: 'from-top' }),
            slideTop: slide({ direction: 'from-bottom' }),
            fade: fade({
                enterStyle: {
                    zIndex: 1
                }
            }),
            none: none(),
            wipeRight: wipe({ direction: 'from-left' }),
            wipeLeft: wipe({ direction: 'from-right' }),
            wipeTop: wipe({ direction: 'from-bottom' }),
            wipeBottom: wipe({ direction: 'from-top' }),
            wipeTopLeft: wipe({ direction: 'from-bottom-right' }),
            wipeTopRight: wipe({ direction: 'from-bottom-left' }),
            wipeBottomLeft: wipe({ direction: 'from-top-right' }),
            wipeBottomRight: wipe({ direction: 'from-top-left' }),
            flipRight: flip({ direction: 'from-left' }),
            flipLeft: flip({ direction: 'from-right' }),
            flipTop: flip({ direction: 'from-bottom' }),
            clockWipe: clockWipe({ width, height }),
            // flipBottom: flip({ direction: 'from-top' }),
            // cubeRight: cube({ direction: 'from-left' }),
            // cubeLeft: cube({ direction: 'from-right' }),
            // cubeTop: cube({ direction: 'from-bottom' }),

        }
        return TransitionAnimation;
    }
    const transitions = getALlTransitions();
    const transitionType = Object.keys(transitions);
    return {
        transitions,
        transitionType,
    };
}

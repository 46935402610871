import { staticFile as staticFileRemotion } from 'remotion'

export const staticFile = (path) => {
    try {
        // @ts-ignore
        const meta = import.meta.env;

        if (meta.VITE_CORE) {
            return `${meta.VITE_CORE}/public/${path}`
        } else {
            return staticFileRemotion(path)
        }

    } catch (error) {
        return staticFileRemotion(path)

    }
}

import React from "react"
import { AbsoluteFill } from "remotion"

interface IBackdropFilterProps {
    style?: React.CSSProperties,
    backgroundElement?: React.ReactNode,
    foregroundElement?: React.ReactNode, // 前景内容做模糊处理
}

export const BackdropFilter: React.FC<IBackdropFilterProps> = ({
    backgroundElement,
    foregroundElement,
    style
}) => {

    return (
        <AbsoluteFill style={style}>
            <AbsoluteFill
                style={{
                    filter: 'blur(11px)',
                }}
            >
                {backgroundElement}
            </AbsoluteFill>
            <AbsoluteFill style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                filter: 'blur(0px)',
            }}>
                {foregroundElement}
            </AbsoluteFill>
        </AbsoluteFill>
    )
}

import { z } from 'zod';
import { BaseSerializer } from '../common/serializer';
import { ISpliceModelData } from './type';

export class Serializer extends BaseSerializer {
    static code = 'SpliceModel';

    private static transitionTime = 5;



    public static get dataSchema() {
        return z.object({});
    }

    public static frameData = ({ props }) => {
        const fps = props.fps ?? 30;
        let totalTime = 0;
        const videoData = props as unknown as ISpliceModelData
        videoData.assets.forEach((asset) => {
            totalTime += asset.length || 0
        })
        return {
            fps,
            transitionTime: Serializer.transitionTime,
            backgroundMusicVolume: props.backgroundMusicVolume,
            durationInFrames: Math.round(totalTime / 1000) * fps,
        };
    }

    data() {
        const arr = [...this.videoData.assets].map((asset, index) => {
            const avatarFrom = 0
            const assetDurationInFrames = asset.length / 1000 * this.fps

            // 获取场景切换的动效果
            let currentTransition = 'none'
            const rule = `[${asset.id}]-[${[...this.videoData.assets][index + 1]?.id}]`
            if (this.videoData.transitions?.[rule]) currentTransition = this.videoData.transitions[rule].type

            return {
                ...asset,
                transitions: this.transitions[currentTransition],
                animation: asset.animation ?? 'zoomIn',
                from: asset.start || avatarFrom,
                durationInFrames: assetDurationInFrames

            }
        }).reverse()

        return arr
    }
}



import { ReactNode, useEffect, useState } from 'react';
import { BackdropFilterImg } from '../backdrop-filter-img';
import { BackdropFilterVideo } from '../backdrop-filter-video';
import { AbsoluteFill, Audio, useCurrentFrame } from 'remotion';
import { DigitalMan } from '../digital-man';
import { linearTiming, TransitionSeries } from '@remotion/transitions';
import { fade } from '@remotion/transitions/fade';



export const SHScene: React.FC<{ item, videoData }> = ({ item, videoData }) => {

    const { avatar, assets, scripts } = item
    const assetsList = assets.map((clip, clipIndex) => {
        const { url, durationInFrames, animation, type, src } = clip
        let useDurationInFrames = durationInFrames

        let layer: ReactNode = null;

        if (type === 'image') {
            const imgSrc = url
            if (!imgSrc) return null
            useDurationInFrames = durationInFrames + (item.extraFrame ?? 0)

            layer = <BackdropFilterImg
                durationInFrames={useDurationInFrames}
                effect={animation}
                src={url}
            />
        }

        if (type === 'video') layer = <BackdropFilterVideo src={url} />

        if (type === 'audio') layer = <Audio src={src} style={{ opacity: 0, objectFit: 'cover', }} />


        return { render: layer ? <AbsoluteFill key={clipIndex}>{layer}</AbsoluteFill> : null, durationInFrames: useDurationInFrames }
    })


    const assetsLayerStyle: React.CSSProperties = avatar.video.placement === 'lowerLayerImage' ? {
        width: '500px',
        height: '500px',
        position: 'absolute',
        left: '50px',
        bottom: '50px',
        overflow: 'hidden',
        borderRadius: '20px',
        zIndex: 1
    } : {}

    let frostedGlass = false

    const getDgitTalStyle = () => {
        const baseStyle = { width: '300px', height: '300px' }
        if (avatar.video.placement === 'topRight') {
            frostedGlass = true
            return {
                borderRadius: '100%',
                overflow: 'hidden',
                right: '20px',
                top: '20px',
                ...baseStyle
            }
        }

        if (avatar.video.placement === 'topLeft') {
            frostedGlass = true
            return {
                borderRadius: '100%',
                overflow: 'hidden',
                left: '20px',
                top: '20px',
                ...baseStyle
            }
        }

        if (avatar.video.placement === 'bottomLeft') {
            frostedGlass = true
            return {
                borderRadius: '100%',
                overflow: 'hidden',
                left: '20px',
                bottom: '20px',
                ...baseStyle
            }
        }

        return {}
    }

    const getSubtitleStyle = () => {
        return avatar.wordsPlacement === 'top' ? { top: '20%' } : { bottom: '20%' }
    }

    const subtitleStyle = getSubtitleStyle()
    const digitTalStyle = getDgitTalStyle()

    const assetsRender = assetsList.reduce((leftScene, rightScene) => {
        return {
            transitions: fade(),
            durationInFrames: (leftScene?.durationInFrames ?? 0) + (rightScene?.durationInFrames ?? 0) - 1,
            time: rightScene.time,
            render: <TransitionSeries >
                {leftScene.durationInFrames && <TransitionSeries.Sequence durationInFrames={leftScene.durationInFrames}>
                    {leftScene.render}
                </TransitionSeries.Sequence>}
                <TransitionSeries.Transition
                    presentation={fade()}
                    timing={linearTiming({ durationInFrames: 1 })}
                />
                <TransitionSeries.Sequence durationInFrames={(leftScene?.durationInFrames ?? 0) + (rightScene?.durationInFrames ?? 0)}>
                    {rightScene.render}
                </TransitionSeries.Sequence>
            </TransitionSeries>
        }
    }).render

    return <span>
        <div
            className='assetsLayer'
            style={assetsLayerStyle}
        >
            {assetsRender}

        </div>
        <AbsoluteFill className='digitalLayer'>
            <DigitalMan
                preload={!videoData.preview}
                video={(!videoData.needAvatar || avatar.video.placement === 'none') ? undefined : {
                    src: avatar.video.url,
                    width: avatar.video?.width,
                    height: avatar.video?.height,
                    style: digitTalStyle,
                    frostedGlass,
                }}
                subtitlesProps={{ position: subtitleStyle }}
                wordsByParagraph={scripts}
                audio={{
                    src: avatar.audio?.url,
                }}
                words={[...avatar.words]}
            />
        </AbsoluteFill>

    </span >
}

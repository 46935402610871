
import React, { useCallback, useEffect, useState } from 'react';
import { OffthreadVideo, Audio, delayRender, continueRender } from 'remotion';
import { SubtitleProps, Subtitle } from '../subtitles';
import { pauseInSentence, easyPauseInSentence } from '../subtitles/helper';
import { playerPreload } from '../../utils/preview';

interface IwordItem {
    word: string;
    start: number;
    end: number
}

interface DigitalManProps {
    video?: {
        src?: string;
        width?: number;
        height?: number;
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
        style?: React.CSSProperties;
        frostedGlass?: boolean;
        frostedGlassColor?: string; // 需要透明度颜色
    },
    audio?: {
        src?: string;
    };
    words: IwordItem[] // 字幕基础格式
    wordsByParagraph?: string[] | undefined// 段落分词
    muted?: boolean // 是否静音

    subtitlesProps?: SubtitleProps // 字幕组件子属性
    preload?: boolean

}




export const DigitalMan = (props: DigitalManProps) => {
    const [useWords, setUseWords] = React.useState(props.words)
    const [handle] = useState(() => delayRender());

    const preload = useCallback(async () => {
        await playerPreload([props?.video?.src])

        continueRender(handle);
    }, []);

    useEffect(() => {
        props.preload && preload()
        console.log(props.wordsByParagraph, props.words)
        if (props?.subtitlesProps?.mode === 'shadowLine') {
            setUseWords(easyPauseInSentence(props.words))
            return
        }
        if (props.wordsByParagraph) setUseWords(pauseInSentence(props.wordsByParagraph, props.words))
        // setUseWords(props.words)

    }, [])





    return <>
        {Boolean(props?.video?.src) &&
            <div style={{
                position: 'absolute',
                width: props.video?.width ?? '100%',
                height: props.video?.height ?? '100%',
                display: 'flex',
                overflow: 'hidden',
                background: props.video?.frostedGlass ? props.video?.frostedGlassColor ?? '#0000003d' : '',
                backdropFilter: props.video?.frostedGlass ? 'blur(10px) saturate(150%)' : '',
                ...{ top: props.video?.top, bottom: props.video?.bottom, left: props.video?.left, right: props.video?.right },
                ...(props.video?.style ?? {}),
            }}>
                <OffthreadVideo
                    transparent
                    src={props.video?.src ?? ''}
                    muted={props?.audio?.src ? true : (props.muted ?? false)}
                    startFrom={2}
                    style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        // ...(props.video.style ?? {}),
                        // width: props.video.width ?? '100%',
                        // height: props.video.height ?? '100%',
                    }} />
            </div>
        }


        {Boolean(props?.audio?.src) &&
            <Audio
                src={props?.audio?.src}
                muted={props.muted ?? false}
                style={{ opacity: 0, objectFit: 'cover', }}
            />
        }
        <Subtitle {...props.subtitlesProps ?? {}} subtitleJson={useWords} />

    </>

}

import { ISubtitleJsonItem } from '../type'
import { TheBoldFont, loadFont } from '../../../aseets/load-font';
// import { loadFont } from "@remotion/google-fonts/Poppins";
interface FollowWordProps {
    subtitleJson: ISubtitleJsonItem[];
    currentSubtitle: ISubtitleJsonItem
    font?: {
        size?: number;
        minHeight?: number;
        weight?: number;
        color?: string;
        highlightColor?: string;
        highlightBackground?: string;
        highlightShadow?: string;
    };

}
loadFont()
const fontFamily = TheBoldFont;

const defaultFont = {
    size: 50,
    minHeight: 150,
    weight: 900,
    color: 'white',
    highlightColor: 'white',
    highlightBackground: '#801cfba8',
    highlightShadow: '2px 2px 4px black'
}

export const FollowWord = ({
    subtitleJson,
    currentSubtitle,
    font = JSON.parse(JSON.stringify(defaultFont))
}: FollowWordProps) => {

    const isNumber = (num) => {
        return !(String(Number(num)) === 'NaN')
    }

    const wordRender = (word) => {
        const pointIndex = word?.split('')?.findIndex((f) => f === '.')
        if (pointIndex > -1 && isNumber(word?.[pointIndex - 1]) || isNumber(word?.[pointIndex + 1])) {
            return word?.replaceAll(',', '')
        }
        return word?.replaceAll(',', '').replaceAll('.', '')
    }

    return (
        <div style={{
            fontSize: font.size ?? defaultFont.size,
            fontWeight: font.weight ?? defaultFont.weight,
            fontFamily,
            minHeight: font.minHeight ?? defaultFont.minHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div>
                {subtitleJson.filter((f) => f.paragraph === currentSubtitle?.paragraph).map((item, index) => (
                    <span key={index}>
                        <span
                            style={{
                                WebkitTextStroke: '3px black',
                                background: item?.active ? font.highlightBackground ?? defaultFont.highlightBackground : 'none',
                                color: item?.active ? font.highlightColor ?? defaultFont.highlightColor : font.color ?? defaultFont.color,
                                textShadow: item?.active ? '2px 2px 4px black' : '2px 2px 4px black',

                            }}
                        >
                            <span
                                style={{
                                    padding: '4px',
                                    position: 'relative',
                                    bottom: '3px'
                                }}
                            >
                                {wordRender(item.word)}
                            </span>

                        </span>
                        {' '}
                    </span>

                ))}
            </div>
        </div>
    )

}

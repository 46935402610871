import { prefetch } from 'remotion';

export const playerPreload = async (preloadList: string[]) => {
    const freeList = []

    for (const url of preloadList) {
        const { free, waitUntilDone } = prefetch(url);
        try {
            await waitUntilDone()
        } catch (error) { }
        freeList.push(free)

    }

    const free = () => {
        freeList.forEach((freeFn) => freeFn?.())
    }

    return free



}




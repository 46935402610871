/* eslint-disable @remotion/warn-native-media-tag */
import React from "react"
import { OffthreadVideo, Video } from 'remotion'
import { BackdropFilter } from '../backdrop-filter'

interface IBackdropFilterVideoProps {
    src: string,
    style?: React.CSSProperties,
    muted?: boolean
    loop?: boolean
}
export const BackdropFilterVideo: React.FC<IBackdropFilterVideoProps> = ({
    src,
    style,
    muted = true,
    loop = true
}) => {
    return <BackdropFilter
        style={style}
        backgroundElement={<OffthreadVideo src={src} muted={muted}
            style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
            }} onError={(error) => console.log(error)} />}
        foregroundElement={
            <OffthreadVideo src={src} muted={muted}
                style={{
                    objectFit: 'cover',
                    width: '100%',
                }} onError={(error) => console.log(error)} />
        }
    />
}


import { staticFile } from '../common/file'

// 主类型
export interface ISceneModelData {
    fps: number;
    backgroundColor: string;
    backgroundMusic?: BackgroundMusic;
    needAvatar: boolean;
    scenes: Scene[];
    transitions: Transitions;
    watermark?: string;
    preview?: boolean;
}

// 背景音乐类型
interface BackgroundMusic {
    url: string;
    volume: number;
}

// 场景类型
interface Scene {
    id: number;
    scripts: Script[];
    assets: Asset[];
    avatar: Avatar;
    needFillTime?: number
}

// 脚本类型
interface Script {
    text: string;
}

type animationType = 'none' | 'zoomIn' | 'slowUp' | 'slowDown' | 'slowRightDown' | 'slowLeftUp' | 'slowRightUp' | 'slowLeftDown';

// 资源类型
interface Asset {
    url: string;
    type: 'image' | 'video';
    animation?: animationType;
    width: number;
    height: number;
    length: number | null;
    duration: number;
}

// 头像类型
interface Avatar {
    video: VideoInfo;
    audio: AudioInfo;
    words: Word[];
    wordsPlacement?: 'top' | 'bottom';
}

// 视频信息类型
interface VideoInfo {
    url: string;
    width: number;
    height: number;
    length: number;
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

// 音频信息类型
interface AudioInfo {
    url: string;
    length: number;
}

// 单词类型
interface Word {
    start: number;
    end: number;
    word: string;
}

// 转场效果类型
type TransitionType = 'fade' | 'none' | 'slideLeft' | 'slideTop';

// 转场效果映射类型
interface Transitions {
    [key: string]: {
        type: TransitionType;
    };
}

// 导出 ISceneModelData 类型的默认值
const data: ISceneModelData = {
    "backgroundColor": '',
    // "backgroundMusic": {
    //     url: staticFile('stableaudio.wav'),
    //     volume: 0.3
    // },
    "needAvatar": true,
    "scenes": [
        {
            "id": 1,
            "scripts": [
                {
                    "text": "Introducing the ultimate tote bag for every occasion!"
                }
            ],
            "assets": [
                {
                    "url": "https://m.media-amazon.com/images/I/91dCWxxOf8L.AC_SL1500.jpg",
                    "type": "image",
                    "width": 1245,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/657d34a18a3162e3d9a2a953bae6fc3c53bcbc947e82e8dc.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 3400
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/90cdfd52e8f1489e8867438296c839e6.mp3",
                    "length": 3400
                },
                "wordsPlacement": "top",
                "words": [
                    {
                        "start": 600,
                        "end": 1160,
                        "word": "Introducing"
                    },
                    {
                        "start": 602,
                        "end": 1162,
                        "word": " "
                    },
                    {
                        "start": 1160,
                        "end": 1320,
                        "word": "the"
                    },
                    {
                        "start": 1162,
                        "end": 1322,
                        "word": " "
                    },
                    {
                        "start": 1320,
                        "end": 1620,
                        "word": "ultimate"
                    },
                    {
                        "start": 1322,
                        "end": 1622,
                        "word": " "
                    },
                    {
                        "start": 1620,
                        "end": 1900,
                        "word": "tote"
                    },
                    {
                        "start": 1622,
                        "end": 1902,
                        "word": " "
                    },
                    {
                        "start": 1900,
                        "end": 2140,
                        "word": "bag"
                    },
                    {
                        "start": 1902,
                        "end": 2142,
                        "word": " "
                    },
                    {
                        "start": 2140,
                        "end": 2240,
                        "word": "for"
                    },
                    {
                        "start": 2142,
                        "end": 2242,
                        "word": " "
                    },
                    {
                        "start": 2420,
                        "end": 2660,
                        "word": "every"
                    },
                    {
                        "start": 2422,
                        "end": 2662,
                        "word": " "
                    },
                    {
                        "start": 2660,
                        "end": 2980,
                        "word": "occasion"
                    }
                ]
            }
        },
        {
            "id": 2,
            "scripts": [
                {
                    "text": "With multiple pockets, you can easily access your phone, keys, and wallet anytime."
                }
            ],
            "assets": [
                {
                    "url": "https://m.media-amazon.com/images/I/81Oq-LC58NL.AC_SL1500.jpg",
                    "type": "image",
                    "width": 1500,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/4cca323a987cb5c3e8ccc41143be1a64b629940ab90d8e8c.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 5213
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/18bd5a237c4648a681e372603cecccc9.mp3",
                    "length": 5244
                },
                "words": [
                    {
                        "start": 580,
                        "end": 760,
                        "word": "With"
                    },
                    {
                        "start": 582,
                        "end": 762,
                        "word": " "
                    },
                    {
                        "start": 760,
                        "end": 1120,
                        "word": "multiple"
                    },
                    {
                        "start": 762,
                        "end": 1122,
                        "word": " "
                    },
                    {
                        "start": 1120,
                        "end": 1560,
                        "word": "pockets"
                    },
                    {
                        "start": 1121,
                        "end": 1561,
                        "word": ","
                    },
                    {
                        "start": 1122,
                        "end": 1562,
                        "word": " "
                    },
                    {
                        "start": 1840,
                        "end": 1880,
                        "word": "you"
                    },
                    {
                        "start": 1842,
                        "end": 1882,
                        "word": " "
                    },
                    {
                        "start": 1880,
                        "end": 2040,
                        "word": "can"
                    },
                    {
                        "start": 1882,
                        "end": 2042,
                        "word": " "
                    },
                    {
                        "start": 2040,
                        "end": 2440,
                        "word": "easily"
                    },
                    {
                        "start": 2042,
                        "end": 2442,
                        "word": " "
                    },
                    {
                        "start": 2440,
                        "end": 2740,
                        "word": "access"
                    },
                    {
                        "start": 2442,
                        "end": 2742,
                        "word": " "
                    },
                    {
                        "start": 2740,
                        "end": 3000,
                        "word": "your"
                    },
                    {
                        "start": 2742,
                        "end": 3002,
                        "word": " "
                    },
                    {
                        "start": 3000,
                        "end": 3400,
                        "word": "phone"
                    },
                    {
                        "start": 3001,
                        "end": 3401,
                        "word": ","
                    },
                    {
                        "start": 3002,
                        "end": 3402,
                        "word": " "
                    },
                    {
                        "start": 3520,
                        "end": 3760,
                        "word": "keys"
                    },
                    {
                        "start": 3521,
                        "end": 3761,
                        "word": ","
                    },
                    {
                        "start": 3522,
                        "end": 3762,
                        "word": " "
                    },
                    {
                        "start": 3960,
                        "end": 4000,
                        "word": "and"
                    },
                    {
                        "start": 3962,
                        "end": 4002,
                        "word": " "
                    },
                    {
                        "start": 4000,
                        "end": 4340,
                        "word": "wallet"
                    },
                    {
                        "start": 4002,
                        "end": 4342,
                        "word": " "
                    },
                    {
                        "start": 4340,
                        "end": 4800,
                        "word": "anytime"
                    },
                    {
                        "start": 4341,
                        "end": 4801,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 3,
            "scripts": [
                {
                    "text": "The large capacity is perfect for carrying your laptop, books, and daily essentials."
                }
            ],
            "assets": [
                {
                    "url": "https://m.media-amazon.com/images/I/81F7gRAmYEL.AC_SL1500.jpg",
                    "type": "image",
                    "width": 1495,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/74bd521fc1569c0367e0edecdfcbf728009a224e2a57bffa.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 5513
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/3a7d85284ba1474793f4e106a2bc2180.mp3",
                    "length": 5544
                },
                "words": [
                    {
                        "start": 620,
                        "end": 740,
                        "word": "The"
                    },
                    {
                        "start": 622,
                        "end": 742,
                        "word": " "
                    },
                    {
                        "start": 740,
                        "end": 980,
                        "word": "large"
                    },
                    {
                        "start": 742,
                        "end": 982,
                        "word": " "
                    },
                    {
                        "start": 980,
                        "end": 1460,
                        "word": "capacity"
                    },
                    {
                        "start": 982,
                        "end": 1462,
                        "word": " "
                    },
                    {
                        "start": 1460,
                        "end": 1660,
                        "word": "is"
                    },
                    {
                        "start": 1462,
                        "end": 1662,
                        "word": " "
                    },
                    {
                        "start": 1780,
                        "end": 2060,
                        "word": "perfect"
                    },
                    {
                        "start": 1782,
                        "end": 2062,
                        "word": " "
                    },
                    {
                        "start": 2060,
                        "end": 2260,
                        "word": "for"
                    },
                    {
                        "start": 2062,
                        "end": 2262,
                        "word": " "
                    },
                    {
                        "start": 2320,
                        "end": 2600,
                        "word": "carrying"
                    },
                    {
                        "start": 2322,
                        "end": 2602,
                        "word": " "
                    },
                    {
                        "start": 2600,
                        "end": 2800,
                        "word": "your"
                    },
                    {
                        "start": 2602,
                        "end": 2802,
                        "word": " "
                    },
                    {
                        "start": 2800,
                        "end": 3200,
                        "word": "laptop"
                    },
                    {
                        "start": 2801,
                        "end": 3201,
                        "word": ","
                    },
                    {
                        "start": 2802,
                        "end": 3202,
                        "word": " "
                    },
                    {
                        "start": 3500,
                        "end": 3660,
                        "word": "books"
                    },
                    {
                        "start": 3502,
                        "end": 3662,
                        "word": " "
                    },
                    {
                        "start": 3840,
                        "end": 3980,
                        "word": "and"
                    },
                    {
                        "start": 3842,
                        "end": 3982,
                        "word": " "
                    },
                    {
                        "start": 3980,
                        "end": 4240,
                        "word": "daily"
                    },
                    {
                        "start": 3982,
                        "end": 4242,
                        "word": " "
                    },
                    {
                        "start": 4240,
                        "end": 4660,
                        "word": "essentials"
                    },
                    {
                        "start": 4241,
                        "end": 4661,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 4,
            "scripts": [
                {
                    "text": "Featuring a removable and adjustable shoulder strap, this bag transforms from a tote to a crossbody in seconds."
                }
            ],
            "assets": [
                {
                    "url": staticFile("bag/v2/bag_360_fps32_3c.mp4"),
                    "type": "video",
                    "width": 854,
                    "height": 480,
                    "length": 3378
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/bd959a1759d3463f896bc11941a02375c92fde6adeb9b213.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 7301
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/edfc2817968f4cc4af5dc0eea25ce1f4.mp3",
                    "length": 7320
                },
                "words": [
                    {
                        "start": 680,
                        "end": 980,
                        "word": "Featuring"
                    },
                    {
                        "start": 682,
                        "end": 982,
                        "word": " "
                    },
                    {
                        "start": 980,
                        "end": 1140,
                        "word": "a"
                    },
                    {
                        "start": 982,
                        "end": 1142,
                        "word": " "
                    },
                    {
                        "start": 1140,
                        "end": 1420,
                        "word": "removable"
                    },
                    {
                        "start": 1142,
                        "end": 1422,
                        "word": " "
                    },
                    {
                        "start": 1420,
                        "end": 1780,
                        "word": "and"
                    },
                    {
                        "start": 1422,
                        "end": 1782,
                        "word": " "
                    },
                    {
                        "start": 1780,
                        "end": 2220,
                        "word": "adjustable"
                    },
                    {
                        "start": 1782,
                        "end": 2222,
                        "word": " "
                    },
                    {
                        "start": 2220,
                        "end": 2620,
                        "word": "shoulder"
                    },
                    {
                        "start": 2222,
                        "end": 2622,
                        "word": " "
                    },
                    {
                        "start": 2620,
                        "end": 3020,
                        "word": "strap"
                    },
                    {
                        "start": 2621,
                        "end": 3021,
                        "word": ","
                    },
                    {
                        "start": 2622,
                        "end": 3022,
                        "word": " "
                    },
                    {
                        "start": 3460,
                        "end": 3600,
                        "word": "this"
                    },
                    {
                        "start": 3462,
                        "end": 3602,
                        "word": " "
                    },
                    {
                        "start": 3600,
                        "end": 3920,
                        "word": "bag"
                    },
                    {
                        "start": 3602,
                        "end": 3922,
                        "word": " "
                    },
                    {
                        "start": 3920,
                        "end": 4520,
                        "word": "transforms"
                    },
                    {
                        "start": 3922,
                        "end": 4522,
                        "word": " "
                    },
                    {
                        "start": 4520,
                        "end": 4880,
                        "word": "from"
                    },
                    {
                        "start": 4522,
                        "end": 4882,
                        "word": " "
                    },
                    {
                        "start": 4880,
                        "end": 4940,
                        "word": "a"
                    },
                    {
                        "start": 4882,
                        "end": 4942,
                        "word": " "
                    },
                    {
                        "start": 4960,
                        "end": 5160,
                        "word": "tote"
                    },
                    {
                        "start": 4962,
                        "end": 5162,
                        "word": " "
                    },
                    {
                        "start": 5160,
                        "end": 5400,
                        "word": "to"
                    },
                    {
                        "start": 5162,
                        "end": 5402,
                        "word": " "
                    },
                    {
                        "start": 5400,
                        "end": 5500,
                        "word": "a"
                    },
                    {
                        "start": 5402,
                        "end": 5502,
                        "word": " "
                    },
                    {
                        "start": 5520,
                        "end": 6020,
                        "word": "crossbody"
                    },
                    {
                        "start": 5522,
                        "end": 6022,
                        "word": " "
                    },
                    {
                        "start": 6020,
                        "end": 6180,
                        "word": "in"
                    },
                    {
                        "start": 6022,
                        "end": 6182,
                        "word": " "
                    },
                    {
                        "start": 6180,
                        "end": 6560,
                        "word": "seconds"
                    },
                    {
                        "start": 6181,
                        "end": 6561,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 5,
            "scripts": [
                {
                    "text": "The magnetic closure ensures your belongings are secure yet easily accessible."
                }
            ],
            "assets": [
                {
                    "url": "https://m.media-amazon.com/images/I/91SNWR1+JyL.AC_SL1500.jpg",
                    "type": "image",
                    "width": 1475,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/8681c443c485c367bf8fc1c12aedebbceb33c9ad75fa4a92.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 4900
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/32cdb0316db54375967ac09dd994c4f7.mp3",
                    "length": 4900
                },
                "words": [
                    {
                        "start": 580,
                        "end": 720,
                        "word": "The"
                    },
                    {
                        "start": 582,
                        "end": 722,
                        "word": " "
                    },
                    {
                        "start": 720,
                        "end": 1100,
                        "word": "magnetic"
                    },
                    {
                        "start": 722,
                        "end": 1102,
                        "word": " "
                    },
                    {
                        "start": 1100,
                        "end": 1520,
                        "word": "closure"
                    },
                    {
                        "start": 1102,
                        "end": 1522,
                        "word": " "
                    },
                    {
                        "start": 1520,
                        "end": 1920,
                        "word": "ensures"
                    },
                    {
                        "start": 1522,
                        "end": 1922,
                        "word": " "
                    },
                    {
                        "start": 1920,
                        "end": 2200,
                        "word": "your"
                    },
                    {
                        "start": 1922,
                        "end": 2202,
                        "word": " "
                    },
                    {
                        "start": 2200,
                        "end": 2540,
                        "word": "belongings"
                    },
                    {
                        "start": 2202,
                        "end": 2542,
                        "word": " "
                    },
                    {
                        "start": 2540,
                        "end": 2820,
                        "word": "are"
                    },
                    {
                        "start": 2542,
                        "end": 2822,
                        "word": " "
                    },
                    {
                        "start": 2820,
                        "end": 3260,
                        "word": "secure"
                    },
                    {
                        "start": 2822,
                        "end": 3262,
                        "word": " "
                    },
                    {
                        "start": 3260,
                        "end": 3520,
                        "word": "yet"
                    },
                    {
                        "start": 3262,
                        "end": 3522,
                        "word": " "
                    },
                    {
                        "start": 3520,
                        "end": 3920,
                        "word": "easily"
                    },
                    {
                        "start": 3522,
                        "end": 3922,
                        "word": " "
                    },
                    {
                        "start": 3920,
                        "end": 4420,
                        "word": "accessible"
                    },
                    {
                        "start": 3921,
                        "end": 4421,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 6,
            "scripts": [
                {
                    "text": "Made from high-quality, waterproof canvas, it's both durable and stylish."
                }
            ],
            "assets": [
                {
                    "url": "https://ziniaofb.oss-cn-hangzhou.aliyuncs.com/full.mp4?3",
                    "type": "video",
                    "width": 1920,
                    "height": 1080,
                    "length": 20487
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/3446f434da233310c676f49a8fbf4b8c09f18277493b9d51.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 5413
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/bf91dda8f7594d009ff1c788355fd788.mp3",
                    "length": 5448
                },
                "words": [
                    {
                        "start": 580,
                        "end": 780,
                        "word": "Made"
                    },
                    {
                        "start": 582,
                        "end": 782,
                        "word": " "
                    },
                    {
                        "start": 780,
                        "end": 1020,
                        "word": "from"
                    },
                    {
                        "start": 782,
                        "end": 1022,
                        "word": " "
                    },
                    {
                        "start": 1020,
                        "end": 1200,
                        "word": "high"
                    },
                    {
                        "start": 1022,
                        "end": 1202,
                        "word": " "
                    },
                    {
                        "start": 1200,
                        "end": 1620,
                        "word": "quality"
                    },
                    {
                        "start": 1202,
                        "end": 1622,
                        "word": " "
                    },
                    {
                        "start": 2080,
                        "end": 2180,
                        "word": "waterproof"
                    },
                    {
                        "start": 2082,
                        "end": 2182,
                        "word": " "
                    },
                    {
                        "start": 2180,
                        "end": 2760,
                        "word": "canvas"
                    },
                    {
                        "start": 2181,
                        "end": 2761,
                        "word": ","
                    },
                    {
                        "start": 2182,
                        "end": 2762,
                        "word": " "
                    },
                    {
                        "start": 3220,
                        "end": 3360,
                        "word": "its"
                    },
                    {
                        "start": 3222,
                        "end": 3362,
                        "word": " "
                    },
                    {
                        "start": 3360,
                        "end": 3560,
                        "word": "both"
                    },
                    {
                        "start": 3362,
                        "end": 3562,
                        "word": " "
                    },
                    {
                        "start": 3600,
                        "end": 3920,
                        "word": "durable"
                    },
                    {
                        "start": 3602,
                        "end": 3922,
                        "word": " "
                    },
                    {
                        "start": 3920,
                        "end": 4260,
                        "word": "and"
                    },
                    {
                        "start": 3922,
                        "end": 4262,
                        "word": " "
                    },
                    {
                        "start": 4260,
                        "end": 4640,
                        "word": "stylish"
                    },
                    {
                        "start": 4261,
                        "end": 4641,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 7,
            "scripts": [
                {
                    "text": "Whether you're heading to work, the gym, or the beach, this bag has got you covered."
                }
            ],
            "assets": [
                {
                    "url": "https://showhype.s3.amazonaws.com/huoshan/7edd555994b84c478c978c7bf9bf63d0.jpg",
                    "type": "image",
                    "width": 1500,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/bb90a2966df38abb69cbe9d7befc1bb6b22fddc4c5acc854.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 5513
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/ba4a2d33f7b647829dbffc7e7fff252b.mp3",
                    "length": 5544
                },
                "words": [
                    {
                        "start": 600,
                        "end": 780,
                        "word": "Whether"
                    },
                    {
                        "start": 602,
                        "end": 782,
                        "word": " "
                    },
                    {
                        "start": 780,
                        "end": 960,
                        "word": "you're"
                    },
                    {
                        "start": 782,
                        "end": 962,
                        "word": " "
                    },
                    {
                        "start": 960,
                        "end": 1160,
                        "word": "heading"
                    },
                    {
                        "start": 962,
                        "end": 1162,
                        "word": " "
                    },
                    {
                        "start": 1160,
                        "end": 1340,
                        "word": "to"
                    },
                    {
                        "start": 1162,
                        "end": 1342,
                        "word": " "
                    },
                    {
                        "start": 1340,
                        "end": 1540,
                        "word": "work"
                    },
                    {
                        "start": 1341,
                        "end": 1541,
                        "word": ","
                    },
                    {
                        "start": 1342,
                        "end": 1542,
                        "word": " "
                    },
                    {
                        "start": 1900,
                        "end": 2020,
                        "word": "the"
                    },
                    {
                        "start": 1902,
                        "end": 2022,
                        "word": " "
                    },
                    {
                        "start": 2020,
                        "end": 2320,
                        "word": "gym"
                    },
                    {
                        "start": 2020,
                        "end": 2320,
                        "word": ","
                    },
                    {
                        "start": 2022,
                        "end": 2322,
                        "word": " "
                    },
                    {
                        "start": 2320,
                        "end": 2760,
                        "word": "or"
                    },
                    {
                        "start": 2322,
                        "end": 2762,
                        "word": " "
                    },
                    {
                        "start": 2760,
                        "end": 2860,
                        "word": "the"
                    },
                    {
                        "start": 2762,
                        "end": 2862,
                        "word": " "
                    },
                    {
                        "start": 2860,
                        "end": 3060,
                        "word": "beach"
                    },
                    {
                        "start": 2861,
                        "end": 3061,
                        "word": ","
                    },
                    {
                        "start": 2862,
                        "end": 3062,
                        "word": " "
                    },
                    {
                        "start": 3660,
                        "end": 3800,
                        "word": "this"
                    },
                    {
                        "start": 3662,
                        "end": 3802,
                        "word": " "
                    },
                    {
                        "start": 3840,
                        "end": 4120,
                        "word": "bag"
                    },
                    {
                        "start": 3842,
                        "end": 4122,
                        "word": " "
                    },
                    {
                        "start": 4120,
                        "end": 4320,
                        "word": "has"
                    },
                    {
                        "start": 4122,
                        "end": 4322,
                        "word": " "
                    },
                    {
                        "start": 4320,
                        "end": 4480,
                        "word": "got"
                    },
                    {
                        "start": 4322,
                        "end": 4482,
                        "word": " "
                    },
                    {
                        "start": 4480,
                        "end": 4600,
                        "word": "you"
                    },
                    {
                        "start": 4482,
                        "end": 4602,
                        "word": " "
                    },
                    {
                        "start": 4600,
                        "end": 4820,
                        "word": "covered"
                    },
                    {
                        "start": 4601,
                        "end": 4821,
                        "word": "."
                    }
                ]
            }
        },
        {
            "id": 8,
            "scripts": [
                {
                    "text": "Experience the perfect blend of functionality and fashion with our versatile tote bag."
                }
            ],
            "assets": [
                {
                    "url": "https://m.media-amazon.com/images/I/81jRf-WJS3L.AC_SL1500.jpg",
                    "type": "image",
                    "width": 1500,
                    "height": 1500,
                    "length": null
                }
            ],
            "avatar": {
                "video": {
                    "url": staticFile("bag/v2/f6f57319e1e0e595cb5596ff38f390aca463e525eac3cd62.webm"),
                    "width": 1350,
                    "height": 1080,
                    "length": 5279
                },
                "audio": {
                    "url": "https://showhype.s3.amazonaws.com/pipio/f34d6b2a78dd4a409af94a4941534a8b.mp3",
                    "length": 5304
                },
                "words": [
                    {
                        "start": 640,
                        "end": 1080,
                        "word": "Experience"
                    },
                    {
                        "start": 642,
                        "end": 1082,
                        "word": " "
                    },
                    {
                        "start": 1080,
                        "end": 1300,
                        "word": "the"
                    },
                    {
                        "start": 1082,
                        "end": 1302,
                        "word": " "
                    },
                    {
                        "start": 1300,
                        "end": 1620,
                        "word": "perfect"
                    },
                    {
                        "start": 1302,
                        "end": 1622,
                        "word": " "
                    },
                    {
                        "start": 1680,
                        "end": 1920,
                        "word": "blend"
                    },
                    {
                        "start": 1682,
                        "end": 1922,
                        "word": " "
                    },
                    {
                        "start": 1920,
                        "end": 2080,
                        "word": "of"
                    },
                    {
                        "start": 1922,
                        "end": 2082,
                        "word": " "
                    },
                    {
                        "start": 2120,
                        "end": 2640,
                        "word": "functionality"
                    },
                    {
                        "start": 2122,
                        "end": 2642,
                        "word": " "
                    },
                    {
                        "start": 2640,
                        "end": 2960,
                        "word": "and"
                    },
                    {
                        "start": 2642,
                        "end": 2962,
                        "word": " "
                    },
                    {
                        "start": 2960,
                        "end": 3320,
                        "word": "fashion"
                    },
                    {
                        "start": 2962,
                        "end": 3322,
                        "word": " "
                    },
                    {
                        "start": 3320,
                        "end": 3560,
                        "word": "with"
                    },
                    {
                        "start": 3322,
                        "end": 3562,
                        "word": " "
                    },
                    {
                        "start": 3560,
                        "end": 3700,
                        "word": "our"
                    },
                    {
                        "start": 3562,
                        "end": 3702,
                        "word": " "
                    },
                    {
                        "start": 3700,
                        "end": 4100,
                        "word": "versatile"
                    },
                    {
                        "start": 3702,
                        "end": 4102,
                        "word": " "
                    },
                    {
                        "start": 4100,
                        "end": 4380,
                        "word": "tote"
                    },
                    {
                        "start": 4102,
                        "end": 4382,
                        "word": " "
                    },
                    {
                        "start": 4380,
                        "end": 4680,
                        "word": "bag"
                    },
                    {
                        "start": 4381,
                        "end": 4681,
                        "word": "."
                    }
                ]
            }
        }
    ],
    "transitions": { // 场景切换动效
        "[1]-[2]": {
            "type": "fade" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        },
        "[2]-[3]": {
            "type": "slideLeft" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        },
        "[3]-[4]": {
            "type": "slideTop" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        }
    }
}

export default data;

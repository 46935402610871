import { useMemo } from 'react';
import {
    AbsoluteFill,
    useVideoConfig,
} from 'remotion';
import { ISpliceModelData } from './type';
import { linearTiming, TransitionSeries } from '@remotion/transitions';
import { useTransitions } from '../../components/hooks/useTransition';
import { Serializer } from './serializer';
import { BackdropFilterImg } from '../../components/backdrop-filter-img';
import { BackdropFilterVideo } from '../../components/backdrop-filter-video';

const transitionTime = 5



export const SpliceModel: React.FC<ISpliceModelData> = (videoData) => {

    const { fps } = useVideoConfig();
    const { transitions } = useTransitions()

    const scenes = useMemo(() => {
        const serializer = new Serializer({ videoData, fps, transitions })
        return serializer.data()
    }, [])

    const scenesComps = [...scenes].map((item, index) => {
        let layer
        if (item.type === 'image') {
            const imgSrc = item.url
            if (!imgSrc) return null

            layer = <BackdropFilterImg
                durationInFrames={item.durationInFrames}
                effect={item.animation}
                src={item.url}
            />
        }

        if (item.type === 'video') layer = <BackdropFilterVideo src={item.url} />


        return {
            transitions: item.transitions,
            duration: item.durationInFrames,
            comp: <>
                {layer ? <AbsoluteFill key={index}>{layer}</AbsoluteFill> : null}
            </>
        }
    })

    return (
        <AbsoluteFill style={{ backgroundColor: '#625454' }}>
            {/* 基础场景组件 */}
            {
                scenesComps.reverse().reduce((leftScene, rightScene) => {
                    return {
                        transitions: rightScene.transitions,
                        duration: leftScene.duration + rightScene.duration - transitionTime
                        ,
                        comp: <TransitionSeries >
                            <TransitionSeries.Sequence durationInFrames={leftScene.duration}>
                                {leftScene.comp}
                            </TransitionSeries.Sequence>
                            <TransitionSeries.Transition
                                presentation={leftScene.transitions}
                                timing={linearTiming({
                                    durationInFrames: transitionTime
                                })}
                            />
                            <TransitionSeries.Sequence durationInFrames={leftScene.duration + rightScene.duration}>
                                {rightScene.comp}
                            </TransitionSeries.Sequence>
                        </TransitionSeries>
                    }
                }).comp
            }

        </AbsoluteFill >
    );
};

import { useCurrentFrame, useVideoConfig } from 'remotion';
import { useEffect, useState } from 'react';
import { ISubtitleJsonItem } from './type'
import { FollowWord } from './render/followWord';
import { ShadowLine } from './render/shadowLine';
import { SingleWord } from './render/singleWord';


export interface SubtitleProps {
    subtitleJson?: ISubtitleJsonItem[];
    position?: {
        bottom?: number | string;
        top?: number | string;
        left?: string;
    };
    font?: {
        size: number;
        weight: number;
        color: string;
        highlightColor: string;
        highlightBackground: string;
        highlightShadow: string;
    };
    cover?: {
        background: string;
        padding: string;
        backdropFilter: string;
    },
    mode?: 'followWord' | 'shadowLine' | 'singleWord'
    | 'followWordBackground' // 背景跟随
    | 'followWordColor' // 字体颜色跟随
}

const defaultPosition = {
    bottom: 150,
    left: '50%',
}

const defaultCover = {
    // background: 80%'rgba(0,0,0,0.5)',
    // backdropFilter: 'blur(10px) saturate(150%)',
    padding: '20px',
}



export const Subtitle = ({
    subtitleJson,
    position = JSON.parse(JSON.stringify(defaultPosition)),
    font,
    cover = JSON.parse(JSON.stringify(defaultCover)),
    mode = 'followWord'
}: SubtitleProps) => {
    const [currentSubtitle, setCurrentSubtitle] = useState<ISubtitleJsonItem>();
    const frame = useCurrentFrame();
    const { fps } = useVideoConfig()


    useEffect(() => {
        const currentTime = frame / fps; // Assuming 30fps
        let currentIndex = 0
        currentIndex = subtitleJson?.findIndex((sub: ISubtitleJsonItem, index) => currentTime >= sub.start && currentTime <= (subtitleJson[index + 1]?.start ?? sub.end)) ?? -1;
        const subtitleItem = subtitleJson?.[currentIndex]
        // 重置状态
        subtitleJson?.forEach((sub) => {
            sub.active = false
        })

        if (subtitleItem) subtitleItem.active = true

        setCurrentSubtitle(subtitleItem);
    }, [frame]);

    // 根据模型序列化
    const serializationMode = () => {
        // 分词检查
        if (['followWord'].includes(mode)) {
            if (subtitleJson?.[0].paragraph === undefined) {
                mode = 'singleWord'
            }
        }

        if (['shadowLine', 'singleWord'].includes(mode)) {
            // cover.backdropFilter = ''
            // cover.background = ''
        } else {
            // cover.backdropFilter = cover.backdropFilter || defaultCover.backdropFilter
            // cover.background = cover.background || defaultCover.background
        }

    }

    serializationMode()

    return (
        <>
            {currentSubtitle && <div
                style={{
                    padding: cover.padding,
                    backdropFilter: cover.backdropFilter,
                    background: cover.background,
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: position.top ? '' : (position.bottom ?? defaultPosition.bottom),
                    top: position.top,
                    left: position.left ?? defaultPosition.left,
                    transform: 'translateX(-50%)',
                    width: '80%'
                }}
            >
                {mode === 'followWord' && <FollowWord subtitleJson={subtitleJson ?? []} currentSubtitle={currentSubtitle} font={font} />}
                {/* 细分类型 */}
                {mode === 'followWordBackground' && <FollowWord subtitleJson={subtitleJson ?? []} currentSubtitle={currentSubtitle} font={font} />}
                {mode === 'followWordColor' && <FollowWord subtitleJson={subtitleJson ?? []} currentSubtitle={currentSubtitle} font={{ highlightBackground: '', highlightColor: 'yellow', ...font }} />}

                {mode === 'shadowLine' && <ShadowLine subtitleJson={subtitleJson ?? []} currentSubtitle={currentSubtitle} font={font} />}
                {mode === 'singleWord' && <SingleWord currentSubtitle={currentSubtitle} font={font} />}
            </div>
            }
        </>
    );
};

import { staticFile } from "remotion"

// 主类型
export interface ISpliceModelData {
    assets: Asset[]
    transitions: Transitions;
}



type animationType = 'none' | 'zoomIn' | 'slowUp' | 'slowDown' | 'slowRightDown' | 'slowLeftTop';

// 资源类型
interface Asset {
    id: number;
    url: string;
    type: 'image' | 'video';
    animation?: animationType;
    width: number;
    height: number;
    length: number | null;
}



// 转场效果类型
type TransitionType = 'fade' | 'none' | 'slideLeft' | 'slideTop';

// 转场效果映射类型
interface Transitions {
    [key: string]: {
        type: TransitionType;
    };
}

// 导出 ISpliceModelData 类型的默认值
const data: ISpliceModelData = {
    "assets": [
        {
            "id": 1,
            "url": staticFile('1-Scene-002.mp4'),
            "type": "video",
            "width": 1475,
            "height": 1500,
            "length": 2000
        },
        {
            "id": 2,
            "url": staticFile('luma-source.mp4'),
            "type": "video",
            "width": 1475,
            "height": 1500,
            "length": 3000
        },
        {
            "id": 3,
            "url": staticFile('mask_temp.mp4'),
            "type": "video",
            "width": 1475,
            "height": 1500,
            "length": 4000
        },
        {
            "id": 4,
            "url": staticFile('1-Scene-002.mp4'),
            "type": "video",
            "width": 1475,
            "height": 1500,
            "length": 5000
        },
    ],
    "transitions": { // 场景切换动效
        "[1]-[2]": {
            "type": "slideLeft" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        },
        "[2]-[3]": {
            "type": "slideLeft" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        },
        "[3]-[4]": {
            "type": "slideLeft" // 动画类型： 'fade', 'none', 'slideLeft', 'slideTop'。 默认为'none'
        }
    }
}

export default data;

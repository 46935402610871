import { interpolate } from 'remotion'
import { translateX, translateY } from '@remotion/animation-utils'
import { useCurrentFrame } from 'remotion'

interface IUseAimationEffectProps {
    durationInFrames: number,
    effect?: 'zoomIn' | 'slowUp' | 'slowDown' | 'slowRightDown' | 'slowLeftUp' | 'slowRightUp' | 'slowLeftDown' | 'slowLeftUp',
}
export const useAimationEffect = ({ durationInFrames, effect }: IUseAimationEffectProps) => {
    const frames = useCurrentFrame()
    const getEffectStyle = () => {
        const scaleValue = 1.1
        const interpolateValue = interpolate(frames, [0, durationInFrames], [-5, 5])
        const slowDownStyele = {
            transform: `${translateY(interpolateValue, '%')}`
        }
        const slowUpStyle = {
            transform: `${translateY(-interpolateValue, '%')}`
        }
        const slowLeftStyle = {
            transform: `${translateX(-interpolateValue, '%')}`
        }
        const slowRightStyle = {
            transform: `${translateX(interpolateValue, '%')}`
        }
        const zoomInStyle = {
            transform: `scale(${interpolate(frames, [0, durationInFrames], [1, 1.3])})`
        }
        const zoomOutStyle = {
            transform: `scale(${interpolate(frames, [0, durationInFrames], [1.3, 1])})`
        }

        if (effect === 'slowUp') {
            return {
                transform: `${slowUpStyle.transform} scale(${scaleValue})`
            }
        }
        if (effect === 'slowDown') {
            return {
                transform: `${slowDownStyele.transform} scale(${scaleValue})`
            }
        }
        if (effect === 'slowRightDown') {
            return {
                transform: `${slowDownStyele.transform} ${slowRightStyle.transform} scale(${scaleValue})`
            }
        }
        if (effect === 'slowRightUp') {
            return {
                transform: `${slowUpStyle.transform} ${slowRightStyle.transform} scale(${scaleValue})`
            }
        }
        if (effect === 'slowLeftTop' || effect === 'slowLeftUp') {
            return {
                transform: `${slowUpStyle.transform} ${slowLeftStyle.transform} scale(${scaleValue})`

            }
        }
        if (effect === 'slowLeftDown') {
            return {
                transform: `${slowDownStyele.transform} ${slowLeftStyle.transform} scale(${scaleValue})`

            }
        }
        if (effect === 'zoomIn') {
            return {
                transform: `${zoomInStyle.transform}`
            }
        }
        if (effect === 'zoomOut') {
            return {
                transform: `${zoomOutStyle.transform}`
            }
        }
    }

    const effectStyle = getEffectStyle()
    return {
        effectStyle
    }
}
